import React from "react"
import PropTypes from "prop-types"
import { usePostMessage } from "../../hooks/usePostMessage"

const AppointmentButton = ({ id, translate }) => {
  const sendBookingId = usePostMessage({
    data: { id: id },
    targetOrigin: "*",
    receiver: window.parent,
  })

  return (
    <button className="appointment-button" onClick={sendBookingId}>
      {translate("info_window.book_appointment")}
    </button>
  )
}

AppointmentButton.propTypes = {
  id: PropTypes.string,
  translate: PropTypes.func,
}

export default AppointmentButton

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ObserveProps from "@geome/react-next/lib/util/observeProps"
import PointRenderer from "@geome/react-components/lib/components/map/renderer/point"
import ClusterRenderer from "@geome/react-components/lib/components/map/renderer/cluster"
import SearchMarkerRenderer from "@geome/shell-locator-components/lib/components/map/renderer/search"
import Marker from "../../../marker"
import Cluster from "@geome/react-components/lib/components/cluster"
import { isClustered } from "@geome/react-components/lib/components/utils/location"

const removeNonMatchingIfTheresClusters = (locations) => {
  if (locations.find((l) => isClustered(l))) {
    return locations.filter((l) => l.resource !== "non_matching_locations")
  }
  return locations
}

class Locations extends React.Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
    selectedLocation: PropTypes.object,
    highlightedLocation: PropTypes.object,
    onOverlayRequest: PropTypes.func.isRequired,
    selectedPlace: PropTypes.object,
  }

  shouldComponentUpdate(nextProps) {
    return ObserveProps(this.props, nextProps, [
      "locations",
      "selectedLocation",
      "highlightedLocation",
    ])
  }

  render() {
    return (
      <Fragment>
        <PointRenderer
          subjects={removeNonMatchingIfTheresClusters(this.props.locations)}
          selected={this.props.selectedLocation}
          highlighted={this.props.highlightedLocation}
          childComponent={Marker}
          overlay={this.props.onOverlayRequest("locations-points")}
          offset={{ left: 20, bottom: 20 }}
        />
        <ClusterRenderer
          subjects={this.props.locations}
          childComponent={Cluster}
          overlay={this.props.onOverlayRequest("locations-clusters")}
        />
        <SearchMarkerRenderer
          selectedPlace={this.props.selectedPlace}
          onOverlayRequest={this.props.onOverlayRequest}
        />
      </Fragment>
    )
  }
}

export default Locations

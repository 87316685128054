import React from "react"

class ChevronUpIcon extends React.Component {
  render() {
    return (
      <svg width="15" height="10" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 8l5.5-6L13 8"
          stroke="#404040"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        />
      </svg>
    )
  }
}

export default ChevronUpIcon

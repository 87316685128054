import AppContainer from "@geome/react-next/lib/appContainer"
import AppLayout from "./appLayout"
import componentIds from "@geome/shell-locator-components/lib/values/componentIds"
import Map from "./map"
import Settings from "./settings"
import PropTypes from "prop-types"
import InfoWindow from "./infoWindow"
import React from "react"
import Resources from "./resources"
import Filters from "./filters"
import LocationsList from "./locationsList"
import RoutingContainer from "@geome/react-components/lib/containers/routing"
import SearchComponent from "./search"
import MobileMapRetargetter from "./mobileMapRetargetter"

class App extends React.Component {
  static propTypes = {
    routerProps: PropTypes.object,
    routingHistory: PropTypes.object,
    routingRegistry: PropTypes.object,
    mapFactory: PropTypes.object,
  }

  render() {
    return (
      <AppContainer className="app-container" {...this.props}>
        <RoutingContainer
          history={this.props.routingHistory}
          registry={this.props.routingRegistry}
        />
        <Resources />
        <AppLayout
          renderHeader={() => <SearchComponent {...this.props.routerProps} />}
          renderMap={() => <Map mapFactory={this.props.mapFactory} />}
          views={{
            [componentIds.INFOWINDOW]: () => <InfoWindow />,
            [componentIds.LOCATIONSLIST]: (props) => <LocationsList {...props} />,
            [componentIds.SETTINGS]: (props) => <Settings {...props} />,
            [componentIds.FILTERS]: (props) => <Filters {...props} />,
            [componentIds.LOCATIONSLISTALONGROUTE]: (props) => <LocationsList {...props} />,
          }}
        />
        <MobileMapRetargetter />
      </AppContainer>
    )
  }
}

export default App

import React from "react"
import FiltersContainer from "@geome/shell-locator-components/lib/containers/filters"
import FiltersView from "@geome/shell-locator-components/lib/components/filters"
import PropTypes from "prop-types"
import { translate } from "@geome/intl"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import { withContexts } from "@geome/react-next/lib/context/withContexts"

const TOP_OPTIONS_MOBILE = 4
const TOP_OPTIONS_DESKTOP = 8

const Filters = (props) => (
  <FiltersContainer>
    {(viewProps) => (
      <FiltersView
        {...viewProps}
        {...props}
        translate={translate}
        numberOfTopOptions={props.isMobile ? TOP_OPTIONS_MOBILE : TOP_OPTIONS_DESKTOP}
        alwaysShowSelectedFilters={true}
        shouldShowFilterIcons={false}
      />
    )}
  </FiltersContainer>
)
Filters.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export { Filters as UnconnectedFilters }
export default withContexts(EnvironmentContext)(Filters)

import AppLayoutContainer from "@geome/shell-locator-components/lib/containers/appLayout"
import AppLayoutView from "./view"
import { translate } from "@geome/intl"
import React from "react"

const AppLayout = props => (
  <AppLayoutContainer>
    {viewProps => <AppLayoutView {...viewProps} {...props} translate={translate} />}
  </AppLayoutContainer>
)

export default AppLayout

import React from "react"

class ChevronRightIcon extends React.Component {
  render() {
    return (
      <svg width="10" height="15" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M.629 1.871L6.257 7.5.63 13.129l1.514 1.514L9.286 7.5 2.143.357z"
          fill="#4A4A4A"
          fillRule="nonzero"
        />
      </svg>
    )
  }
}

export default ChevronRightIcon

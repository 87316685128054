import React from "react"

class ChevronLeftIcon extends React.Component {
  render() {
    return (
      <svg width="10" height="15" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.286 13.129L3.657 7.5l5.629-5.629L7.77.357.63 7.5l7.142 7.143z"
          fill="#4A4A4A"
          fillRule="nonzero"
        />
      </svg>
    )
  }
}

export default ChevronLeftIcon

import React from "react"
import PropTypes from "prop-types"
import ResponsiveSearchBox from "@geome/shell-locator-components/lib/components/responsiveSearchBox"
import Pane from "@geome/shell-locator-components/lib/components/pane"
import { translate } from "@geome/intl"
import BackButton from "@geome/shell-locator-components/lib/components/backButton"
import SettingsButton from "../settings/button"
import InlineFilters from "../filters/inlineFilters"
import BarrelIcon from "../../../images/barrel.svg"

class SearchView extends React.Component {
  static propTypes = {
    isGeolocationAvailable: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSettingsWindowActive: PropTypes.bool,
    selectedLocation: PropTypes.object,
    onGeolocationSelect: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    unselectLocation: PropTypes.func.isRequired,
    restoreBookmark: PropTypes.func.isRequired
  }

  static defaultProps = {
    isMobile: false,
    translate: translate
  }

  handleBackToResultsButtonClick() {
    this.props.unselectLocation()
    this.props.restoreBookmark()
  }

  renderBackButton() {
    if (!this.props.selectedLocation || this.props.isSettingsWindowActive) return null
    return (
      <BackButton
        className="search-view__back-button"
        onClick={this.handleBackToResultsButtonClick.bind(this)}
      >
        {this.props.translate("info_window.back_to_results")}
      </BackButton>
    )
  }

  getSpacingClasses() {
    return this.props.isMobile
      ? "group-padding-left-small group-padding-y-small"
      : "group-padding-left"
  }

  render() {
    return (
      <Pane innerClassName={this.getSpacingClasses()}>
        <div className="u-flex-row group-padding-y">
          <div className="u-flex-grow-1">
            <ResponsiveSearchBox
              {...this.props}
              brandImagePicker={() => BarrelIcon}
              isMobile={this.props.isMobile}
              placeholder={this.props.translate("search.placeholder")}
              geolocationButtonText={this.props.translate("search.geolocation")}
              shouldShowGeolocationButton={this.props.isGeolocationAvailable}
              onDirectionsButtonClick={this.props.onRouteToDirections}
              onGeolocationButtonClick={this.props.onGeolocationSelect}
              shouldShowDirectionsButton={false}
              translate={this.props.translate}
              resourceCompletions={true}
            />
          </div>
          <SettingsButton />
        </div>
        <div className="u-flex-row inline-filters-container">
          <div className="u-flex-grow-1">
            <InlineFilters />
          </div>
        </div>
        {this.renderBackButton()}
      </Pane>
    )
  }
}

export default SearchView

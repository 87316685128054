import React from "react"
import SettingsContainer from "@geome/shell-locator-components/lib/containers/settings"
import SettingsBase from "@geome/shell-locator-components/lib/components/settings"
import { translate } from "@geome/intl"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import PropTypes from "prop-types"

const Settings = (props) => {
  return (
    <SettingsContainer>
      {(viewProps) => <SettingsBase {...viewProps} translate={translate} />}
    </SettingsContainer>
  )
}

Settings.propTypes = {
  layoutToggleWindow: PropTypes.func.isRequired,
}

const commands = {
  layoutToggleWindow: "layout.toggleWindow",
}

export default connectToFlux({}, commands)(Settings)

import React from "react"
import SettingsContainer from "@geome/shell-locator-components/lib/containers/settings/button"
import SettingsBase from "@geome/shell-locator-components/lib/components/settings/button"
import { translate } from "@geome/intl"

const SettingsButton = props => (
  <SettingsContainer>
    {viewProps => <SettingsBase {...viewProps} {...props} translate={translate} className="settings-button" />}
  </SettingsContainer>
)

export default SettingsButton

import React from "react"
import LocationsListContainer from "@geome/shell-locator-components/lib/containers/locationsList"
import LocationsListView from "@geome/shell-locator-components/lib/components/locationsList"
import { OpenInGoogleMaps } from "../utils/openInGoogleMaps"
import { translate } from "@geome/intl"

class LocationsList extends React.PureComponent {
  locationAddress({ address1, city }) {
    return [address1, city].filter(Boolean).join(", ")
  }

  render() {
    return (
      <LocationsListContainer>
        {(viewProps) => {
          const locationsWithAddresses = viewProps.locations.map((l) => ({
            ...l,
            address: this.locationAddress(l),
          }))

          return (
            <LocationsListView
              {...viewProps}
              locations={locationsWithAddresses}
              translate={translate}
              shouldShowDirectionsButton
              onDirectionsClick={(location) => OpenInGoogleMaps(location)}
              iconListProperties={[]}
            />
          )
        }}
      </LocationsListContainer>
    )
  }
}

export default LocationsList

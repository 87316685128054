import RegionalTargets from "@geome/intl/lib/regionalTargets"
const deployEnv = process.env.DEPLOY_ENV || "dev"

const configEnv = ["test", "uat", "prod"].includes(deployEnv) ? deployEnv : "test"
const baseUrl = "/api/v1/global_lubes"
const url = new URL(window.document.location.href)
const locale = url.searchParams.get("locale")

export default {
  configEnv: configEnv,
  deployEnv: deployEnv,
  remote: "/config/{config_status}/globallubricants/{configEnv}/{locale}.json",
  directions: {
    options: {
      unitSystem: 1,
    },
  },
  baseUrl: locale === "ar_EG" ? `${baseUrl}/arabic` : baseUrl,
  gApiSettings: {
    key: process.env.GOOGLE_API_KEY,
  },
  resources: {
    locations: {
      enabled: true,
    },
    non_matching_locations: {
      enabled: true,
    },
  },
  map: {
    settings: {
      zoom: 6,
      center: {
        lat: 55,
        lng: -4,
      },
    },
    regionalTargets: RegionalTargets,
  },
}

import React from "react"
import Locations from "./locations"
import Map from "@geome/shell-locator-components/lib/components/map"
import PropTypes from "prop-types"

const MapView = props => (
  <Map {...props}>
    {({ handleOverlayRequest }) => (
      <Locations
        onOverlayRequest={handleOverlayRequest}
        locations={props.locations}
        selectedLocation={props.selectedLocation}
        highlightedLocation={props.highlightedLocation}
        selectedPlace={props.selectedPlace}
      />
    )}
  </Map>
)

MapView.propTypes = {
  selectedLocation: PropTypes.object,
  highlightedLocation: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object),
  selectedPlace: PropTypes.object
}

export default MapView

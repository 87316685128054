import React, { Fragment } from "react"
import PropTypes from "prop-types"
import BackButton from "@geome/shell-locator-components/lib/components/backButton"
import Pane from "@geome/shell-locator-components/lib/components/pane"
import MinimiseToggle from "../minimiseToggle"
import ViewSwitcher, {
  getCurrentViewId
} from "@geome/shell-locator-components/lib/components/appLayout/viewSwitcher"

const clamp = (low, number, high) => Math.max(low, Math.min(number, high))

class AppLayoutView extends React.PureComponent {
  static propTypes = {
    isMinimised: PropTypes.bool,
    isMobile: PropTypes.bool,
    selectedLocation: PropTypes.object,
    views: PropTypes.object.isRequired,
    viewportHeight: PropTypes.number.isRequired,
    changeAppPanelDimensions: PropTypes.func.isRequired,
    getElementDimensions: PropTypes.func.isRequired,
    renderMap: PropTypes.func.isRequired,
    renderHeader: PropTypes.func.isRequired,
    restoreBookmark: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    unselectLocation: PropTypes.func.isRequired,
    shouldShowEmptyLocationsList: PropTypes.bool.isRequired,
    isShowingAlongRoute: PropTypes.bool.isRequired
  }

  static defaultProps = {
    appPanelDimensions: {},
    getElementDimensions: el => el.getBoundingClientRect(),
    translate: m => m,
    shouldShowEmptyLocationsList: false,
    isShowingAlongRoute: false
  }

  state = {
    mobileMapViewEnabled: false,
    mobileMapWindowOffset: 0
  }

  componentDidMount() {
    this.setAppPanelDimensions()
    this.setMinMapWindowHeight()
    this.setupBottomContentTouchHandler()
  }

  setupBottomContentTouchHandler() {
    // these can't be setup via react
    // since react's touchMove's `preventDefault` doesn't preventDefault
    // However for the purposes of tests, it is also defined as a prop
    this.bottomContent?.addEventListener("touchmove", e =>
      this.handleBottomContentTouchMoveAttempt(e)
    )
  }

  componentDidUpdate(prevProps) {
    this.maybeResetScrollPosition(prevProps)
    this.maybeDisableMobileMapView(prevProps)
    this.setAppPanelDimensions()
    this.setMinMapWindowHeight()
  }

  setMinMapWindowHeight() {
    const { getElementDimensions } = this.props
    if (this.mapWindow && !this.mapWindowInitialHeight) {
      this.mapWindowInitialHeight = Math.round(getElementDimensions(this.mapWindow).height)
    }
  }

  shouldScroll(deltaY) {
    const currentOffset = this.state.mobileMapWindowOffset
    const contentHasScrolledToTop = this.bottomContent.scrollTop === 0
    const shouldScrollUp = currentOffset < this.mapWindowInitialHeight - 10 && deltaY > 0
    const shouldScrollDown = currentOffset > 0 && contentHasScrolledToTop && deltaY < 0
    return shouldScrollUp || shouldScrollDown
  }

  handleBottomContentScrollAttempt(e) {
    if (!this.props.isMobile) return
    const deltaY = e.deltaY
    if (this.shouldScroll(deltaY)) {
      e.preventDefault()
      this.setState({
        mobileMapWindowOffset: clamp(
          0,
          this.state.mobileMapWindowOffset + deltaY,
          this.mapWindowInitialHeight - 10
        )
      })
    }
  }

  handleBottomContentTouchMoveAttempt(e) {
    if (!this.props.isMobile) return
    const deltaY = this.lastTouchY - e.changedTouches[0].screenY || 0
    this.lastTouchY = e.changedTouches[0].screenY
    if (this.shouldScroll(deltaY)) {
      e.preventDefault()
      this.setState({
        mobileMapWindowOffset: clamp(
          0,
          this.state.mobileMapWindowOffset + deltaY,
          this.mapWindowInitialHeight - 10
        )
      })
      this.bottomContent.scrollTop = 0
    }
  }

  handleBottomContentTouchStart(e) {
    this.lastTouchY = e.changedTouches[0].screenY
  }

  handleBottomContentTouchEnd() {
    this.lastTouchY = null
  }

  setAppPanelDimensions() {
    window.requestAnimationFrame(() => {
      const { getElementDimensions, viewportHeight, appPanelDimensions } = this.props
      const bottomPanelHeight = getElementDimensions(this.bottomContent).height
      const topPanelHeight = getElementDimensions(this.topContent).height
      const visibleMapHeight = viewportHeight - bottomPanelHeight - topPanelHeight
      const topPanelHeightHasChanged = appPanelDimensions.topPanelHeight != topPanelHeight
      const visibleMapHeightHasChanged = appPanelDimensions.visibleMapHeight != visibleMapHeight
      const topPanelWidth = getElementDimensions(this.topContent).width

      if (topPanelHeightHasChanged || visibleMapHeightHasChanged) {
        this.props.changeAppPanelDimensions({
          topPanelHeight,
          topPanelWidth,
          visibleMapHeight
        })
      }
    })
  }

  maybeResetScrollPosition(prevProps) {
    const prevViewId = getCurrentViewId(prevProps)
    const currentViewId = getCurrentViewId(this.props)
    if (prevViewId !== currentViewId && this.bottomContent) {
      window.requestAnimationFrame(() => {
        this.bottomContent.scrollTop = 0
      })
    }
  }

  maybeDisableMobileMapView(prevProps) {
    if (!prevProps.selectedLocation && this.props.selectedLocation) {
      this.setState({ mobileMapViewEnabled: false })
    }
  }

  shouldRenderMapWindow() {
    return (
      this.props.isMobile &&
      !this.state.mobileMapViewEnabled &&
      getCurrentViewId(this.props) != null
    )
  }

  handleMapWindowClick() {
    const { selectedLocation, unselectLocation } = this.props
    if (selectedLocation) unselectLocation()
    this.setState({ mobileMapViewEnabled: true })
  }

  renderMapWindow() {
    if (!this.shouldRenderMapWindow()) return null
    return (
      <div
        className="app-layout__map-window"
        style={{ height: this.mapWindowInitialHeight - this.state.mobileMapWindowOffset || null }}
        onClick={() => this.handleMapWindowClick()}
        onTouchStart={() => this.handleMapWindowClick()}
        ref={node => (this.mapWindow = node)}
      />
    )
  }

  handleBackButtonClick() {
    this.setState({ mobileMapViewEnabled: false })
    this.props.restoreBookmark()
  }

  renderFooter() {
    if (!this.state.mobileMapViewEnabled) return null
    return (
      <div className="app-layout__footer">
        <Pane innerClassName="group-padding-x-small group-padding-y">
          <BackButton onClick={() => this.handleBackButtonClick()}>
            {this.props.translate("action_panel.back_to_results")}
          </BackButton>
        </Pane>
      </div>
    )
  }

  renderContent() {
    const { isMinimised, renderHeader, isMobile } = this.props
    return (
      <Fragment>
        <div
          className={`app-layout__top-content ${isMinimised ? "is-minimised" : ""}`}
          ref={node => (this.topContent = node)}
        >
          {renderHeader()}
          {!isMobile && <MinimiseToggle />}
        </div>

        {this.renderMapWindow()}
        <div
          id="app-layout__bottom-content"
          className={`app-layout__bottom-content ${isMinimised ? "is-minimised" : ""}`}
          ref={node => (this.bottomContent = node)}
          onWheel={e => this.handleBottomContentScrollAttempt(e)}
          onTouchStart={e => this.handleBottomContentTouchStart(e)}
          onTouchMove={e => this.handleBottomContentTouchMoveAttempt(e)}
          onTouchEnd={e => this.handleBottomContentTouchEnd(e)}
        >
          <ViewSwitcher {...this.props} onlyShowModals={this.state.mobileMapViewEnabled} />
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <div className="app-layout">
        <div className="app-layout__map-container">{this.props.renderMap()}</div>
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default AppLayoutView

import React from "react"
import QuickFiltersContainer from "@geome/react-components/lib/containers/quickFilters"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import InlineFiltersView from "./view"
import { translate } from "@geome/intl"

function InlineFilters(props) {
  return (
    <QuickFiltersContainer showActiveFilters={false}>
      {(viewProps) => {
        return (
          <InlineFiltersView
            {...viewProps}
            {...props}
            translate={translate}
            onFilterToggle={({ active, ...filter }) => props.onFilterToggle(filter)}
          />
        )
      }}
    </QuickFiltersContainer>
  )
}

const commands = {
  onFilterToggle: "filters.toggle",
}

const queries = {
  filterGroups: "filters.config.groups",
  activeFilterCount: "filters.activeCount",
  allFilters: "filters",
}

export default withContexts(EnvironmentContext)(connectToFlux(queries, commands)(InlineFilters))

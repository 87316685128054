import React from "react"
import PropTypes from "prop-types"
import withI18n from "@geome/react-next/lib/withI18n"

class LocationTooltip extends React.Component {
  static propTypes = {
    address1: PropTypes.string,
    name: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    postcode: PropTypes.string,
    telephone: PropTypes.string,
    city: PropTypes.string,
    lube_products: PropTypes.arrayOf(PropTypes.string),
    channel_types: PropTypes.arrayOf(PropTypes.string),
    sectors: PropTypes.arrayOf(PropTypes.string),
    user_type: PropTypes.string,
    pickup_or_delivery: PropTypes.string,
  }

  static defaultProps = {
    translate: (m) => m,
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const {
      translate,
      name,
      address1,
      city,
      postcode,
      telephone,
      lube_products: lubeProducts,
      channel_types: channelTypes,
      pickup_or_delivery: pickupOrDelivery,
      user_type: userType,
      sectors,
    } = this.props

    return (
      <div className="location-tooltip">
        <div className="location-tooltip__title">{name}</div>
        <div className="location-tooltip__body location-tooltip__body--address">
          {[address1, city, postcode].filter(Boolean).join(", ")}
        </div>
        {telephone && (
          <div className="location-tooltip__body location-tooltip__body--telephone">
            {telephone}
          </div>
        )}
        {lubeProducts && lubeProducts.length > 0 && (
          <div className="location-tooltip__body location-tooltip__body--lube-products">
            <strong>{translate("filters.lube_products.title")}: </strong>
            {lubeProducts.map((lp) => translate(`lube_products.${lp}`)).join(", ")}
          </div>
        )}
        {pickupOrDelivery && (
          <div className="location-tooltip__body location-tooltip__body--delivery-or-pickup">
            <strong>{translate("filters.pickup_or_delivery.title")}: </strong>
            {translate(`pickup_or_delivery.${pickupOrDelivery}`)}
          </div>
        )}
        {channelTypes && channelTypes.length > 0 && (
          <div className="location-tooltip__body location-tooltip__body--channel-types">
            <strong>{translate("filters.channel_types.title")}: </strong>
            {channelTypes.map((ct) => translate(`channel_types.${ct}`)).join(", ")}
          </div>
        )}
        {sectors && sectors.length > 0 && (
          <div className="location-tooltip__body location-tooltip__body--sectors">
            <strong>{translate("filters.sectors.title")}: </strong>
            {sectors.map((s) => translate(`sectors.${s}`)).join(", ")}
          </div>
        )}
        {userType && (
          <div className="location-tooltip__body location-tooltip__body--user-type">
            <strong>{translate("filters.user_types.title")}: </strong>
            {translate(`user_types.${userType}`)}
          </div>
        )}
      </div>
    )
  }
}

export default withI18n(LocationTooltip)

import React, { Component } from "react"
import Up from "./up"
import Right from "./right"
import Down from "./down"
import Left from "./left"
import PropTypes from "prop-types"

const propToChevronMapping = {
  up: Up,
  right: Right,
  down: Down,
  left: Left
}

class ChevronIcon extends Component {
  static propTypes = {
    direction: PropTypes.oneOf(["up", "right", "down", "left"]).isRequired
  }

  render() {
    const Chevron = propToChevronMapping[this.props.direction]
    return <Chevron />
  }
}

export default ChevronIcon

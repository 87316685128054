import React from "react"
import PropTypes from "prop-types"
import MapContainer from "@geome/shell-locator-components/lib/containers/map"
import View from "./view"

const Map = props => <MapContainer>{viewProps => <View {...viewProps} {...props} />}</MapContainer>

Map.propTypes = {
  mapFactory: PropTypes.shape({ createMap: PropTypes.func.isRequired }).isRequired
}

export default Map

import React from "react"
import Address from "@geome/shell-locator-components/lib/components/locationInfo/sections/address"
import PropTypes from "prop-types"
import { translate } from "@geome/intl"

class LocationDetails extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      id: PropTypes.string.isRequired,
      esi_code: PropTypes.string,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      address1: PropTypes.string,
      city: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired
    }),
    translate: PropTypes.func.isRequired
  }

  static defaultProps = {
    translate: translate
  }

  addressSubSection() {
    const location = this.props.location
    const locationWithAddress = {
      address: [location.address1].filter(Boolean).join(", "),
      ...location
    }
    return <Address location={locationWithAddress} />
  }

  render() {
    const subSections = [this.addressSubSection()]
    return (
      <div>
        {subSections.map((sub, index) => (
          <div className="location-info__section-head-subsection" key={index}>
            {sub}
          </div>
        ))}
      </div>
    )
  }
}

export default LocationDetails

import React from "react"
import LocationInfoContainer from "@geome/shell-locator-components/lib/containers/locationInfo"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import InfoWindowView from "./view"

const InfoWindow = (props) => (
  <LocationInfoContainer>
    {(viewProps) => (
      <InfoWindowView
        {...viewProps}
        {...props}
        shouldShowAppointmentButton={Boolean(props.shouldShowAppointmentButton)}
      />
    )}
  </LocationInfoContainer>
)

const queries = {
  shouldShowAppointmentButton: "config.appointment_button",
}

export default connectToFlux(queries, {})(withContexts(EnvironmentContext)(InfoWindow))

import { useState, useEffect } from "react"
import { EnvironmentContext } from "@geome/react-next/lib/context/environmentContext"
import { withContexts } from "@geome/react-next/lib/context/withContexts"
import connectToFlux from "@geome/react-next/lib/connectToFlux"
import PropTypes from "prop-types"
import { isClustered } from "@geome/react-components/lib/components/utils/location"

const MobileMapRetargetter = ({
  mapInitialTarget,
  isMobile,
  locationsBounded,
  locationsRadial,
  mapChangeTarget,
}) => {
  const [shouldRetarget, setShouldRetarget] = useState(isMobile)
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false)

  useEffect(() => {
    if (!shouldRetarget) return
    if (!hasRenderedOnce) {
      setHasRenderedOnce(true)
      // not a big fan of reading the window hash directly here
      // but this check means if the map loads already pointed at a position we don't retarget
      if (window.location.hash.includes("@")) {
        setShouldRetarget(false)
        return
      }
    }

    if (locationsBounded?.length > 0 && isClustered(locationsBounded[0])) {
      setShouldRetarget(false)
      return
    }
    if (locationsRadial?.length > 0) {
      window.requestAnimationFrame(() => mapChangeTarget(mapInitialTarget))
      setShouldRetarget(false)
      return
    }
  })
  return null
}

const commands = {
  mapChangeTarget: "map.changeTarget",
}

const queries = {
  mapInitialTarget: "map.initialTarget",
  locationsRadial: "locations.views.radial",
  locationsBounded: "locations.views.bounded",
}

MobileMapRetargetter.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export { MobileMapRetargetter as UnwrappedMobileMapRetargetter }
export default withContexts(EnvironmentContext)(
  connectToFlux(queries, commands)(MobileMapRetargetter)
)

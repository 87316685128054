import React from "react"
import ReactDOM from "react-dom"
import Config from "./config"
import AppComponent from "./components/app"
import Inject from "@geome/inject"

import FluxBasicsFeature from "@geome/sdk/lib/app/feature/flux/basics"
import FluxConfigFeature from "@geome/sdk/lib/app/feature/flux/config"
import FluxDirectionsFeature from "@geome/sdk/lib/app/feature/flux/directions"
import FluxFiltersFeature from "@geome/sdk/lib/app/feature/flux/filters"
import FluxGeoLocationFeature from "@geome/sdk/lib/app/feature/flux/geolocation"
import FluxMapFeature from "@geome/sdk/lib/app/feature/flux/map"
import FluxRoutingFeature from "@geome/sdk/lib/app/feature/flux/routing"
import FluxSearchFeature from "@geome/sdk/lib/app/feature/flux/search"
import GoogleSearchFeature from "@geome/sdk/lib/app/feature/google/search"
import ResourceCompletionFeature from "@geome/sdk/lib/app/feature/resource/completion"
import ResourceFindFeature from "@geome/sdk/lib/app/feature/resource/find"
import ResourceRoutingFeature from "@geome/sdk/lib/app/feature/resource/routing"
import SearchFeature from "@geome/sdk/lib/app/feature/search"
import SearchRoutingFeature from "@geome/sdk/lib/app/feature/search/routing"

import LibActionTypesValue from "@geome/shell-locator-components/lib/values/actionTypes"
import ActionTypesValue from "./values/actionTypes"

import SearchStore from "@geome/sdk/lib/app/store/search"
import LayoutStore from "@geome/shell-locator-components/lib/stores/layout"
import FiltersStore from "@geome/shell-locator-components/lib/stores/filters"
import LocationsStore from "@geome/shell-locator-components/lib/stores/locations"

import LayoutToggleMinimiseCommand from "@geome/shell-locator-components/lib/commands/view/layout/toggleMinimise"
import LayoutToggleWindowCommand from "@geome/shell-locator-components/lib/commands/view/layout/toggleWindow"
import SelectDistanceUnitCommand from "@geome/shell-locator-components/lib/commands/view/settings/selectDistanceUnit"
import RadioToggleFilterCommand from "@geome/shell-locator-components/lib/commands/view/filters/radioToggle"
import LocationSelectCommand from "@geome/shell-locator-components/lib/commands/view/locations/select"
import FiltersInitialize from "@geome/sdk/lib/app/command/view/filters/initialize"
import FiltersToggle from "@geome/sdk/lib/app/command/view/filters/toggle"

import "../iframe-test.html"

const { feature, resolve, register, initializer, start } = Inject()

feature({
  FluxBasicsFeature,
  FluxConfigFeature,
  FluxDirectionsFeature,
  FluxFiltersFeature,
  FluxGeoLocationFeature,
  FluxMapFeature,
  FluxRoutingFeature,
  FluxSearchFeature,
  GoogleSearchFeature,
  ResourceCompletionFeature,
  ResourceFindFeature,
  ResourceRoutingFeature,
  SearchFeature,
  SearchRoutingFeature,
})

register("registry::flux::actionTypes", LibActionTypesValue.concat(ActionTypesValue))

register("registry::flux::commands", {
  "locations.select"() {
    return {
      class: LocationSelectCommand,
      deps: {
        window,
      },
    }
  },
  "layout.toggleWindow"() {
    return {
      class: LayoutToggleWindowCommand,
    }
  },
  "layout.toggleMinimise"() {
    return {
      class: LayoutToggleMinimiseCommand,
    }
  },
  "filters.radioToggle"() {
    return {
      class: RadioToggleFilterCommand,
    }
  },
  "settings.selectDistanceUnit"(app) {
    return {
      class: SelectDistanceUnitCommand,
      deps: {
        gApi: app.resolve("gApi"),
      },
    }
  },
  "filters.initialize"() {
    return {
      class: FiltersInitialize,
    }
  },
  "filters.toggle"() {
    return {
      class: FiltersToggle,
    }
  },
})

register("registry::routes", {
  "/"() {
    return {
      match: ["/"],
      component: AppComponent,
      default: true,
    }
  },
})

register("registry::resources", {
  locations: () => ({
    views: {
      bounded: {
        type: "bounded",
      },
      radial: {
        type: "radial",
      },
    },
  }),
  non_matching_locations: () => ({
    views: {
      bounded: {
        type: "bounded",
      },
    },
  }),
})

register("registry::flux::stores", {
  layout() {
    return { class: LayoutStore }
  },
  search() {
    return { class: SearchStore }
  },
  locations() {
    return { class: LocationsStore }
  },
  filters() {
    return { class: FiltersStore }
  },
})

initializer("Flux Filters", function (app) {
  const filters = app.resolve("config").get("filters")
  return app.resolve("flux::commandHandler").run("filters.initialize", filters)
})

initializer("App", (app) => {
  const directionsOptions = app.resolve("config").get("directions.options")
  app.resolve("flux::commandHandler").run("directions.initializeOptions", directionsOptions)

  render(AppComponent)
})

const getAppProps = () => ({
  get() {},
  i18n: resolve("i18n"),
  commandHandler: resolve("flux::commandHandler"),
  queryHandler: resolve("flux::queryHandler"),
  routingHistory: resolve("routing::history"),
  routingRegistry: resolve("routing::registry"),
  mapFactory: resolve("map::factory"),
  routerProps: {
    routes: resolve("routing::registry").getComponentRoutes(),
    history: resolve("routing::history"),
  },
})

const render = (Component) => {
  ReactDOM.render(<Component {...getAppProps()} />, document.getElementById("app"))
}

document.addEventListener("DOMContentLoaded", () => start(Config))

import React from "react"
import BaseMarker from "@geome/react-components/lib/components/marker"
import LocationTooltip from "../locationTooltip"
import MarkerIcon from "../../../images/lubes-marker.svg"
import NonMatchingMarkerIcon from "../../../images/non_matching_marker.svg"

const Marker = (props) => {
  const isDisabled = props.subject.resource === "non_matching_locations"
  const icon = isDisabled ? NonMatchingMarkerIcon : MarkerIcon

  return (
    <BaseMarker {...props} interactionDisabled={isDisabled}>
      {props.isHighlighted && !props.isSelected && <LocationTooltip {...props.subject} />}
      <img src={icon} width="40" height="40" className={"marker__icon"} />
    </BaseMarker>
  )
}

Marker.propTypes = BaseMarker.propTypes

export default Marker

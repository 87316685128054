import React from "react"
import SearchContainer from "@geome/shell-locator-components/lib/containers/search"
import { translate } from "@geome/intl"
import View from "./view"

const Search = props => (
  <SearchContainer>
    {viewProps => <View {...viewProps} {...props} translate={translate} />}
  </SearchContainer>
)

export default Search

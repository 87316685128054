import React from "react"
import PropTypes from "prop-types"
import FunnelIcon from "../../icons/funnelIcon"
import Chevron from "../../icons/chevron"
import QuickFilters from "@geome/react-components/lib/components/quickFilters"

class InlineFilters extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onFilterToggle: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    isFilterPanelOpen: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    funnelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    annotation: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    selectedLocation: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    activeFilterCount: PropTypes.number,
  }

  static defaultProps = {
    translate: (m) => m,
    filters: [],
    funnelIcon: FunnelIcon,
  }

  renderChevron() {
    const direction = this.props.isFilterPanelOpen ? "up" : "down"
    return <Chevron direction={direction} />
  }

  renderButton() {
    const { translate, annotation, onClick, isFilterPanelOpen, activeFilterCount } = this.props
    const Icon = this.props.funnelIcon

    return (
      <div className="inline-filters__button-container">
        <button className="inline-filters__button" onClick={onClick}>
          <div className="u-flex-row">
            <Icon className="inline-filters__button-icon" active={activeFilterCount > 0} />
            <span className="inline-filters__count">
              {activeFilterCount > 0 && `(${activeFilterCount})`}
            </span>
            <div className="inline-filters__button-text">
              {isFilterPanelOpen ? translate("filters.less") : translate("filters.more")}
            </div>
            <span className="inline-filters_button--chevron">{this.renderChevron()}</span>
          </div>
        </button>

        {annotation && <div className="inline-filters__annotation">{annotation}</div>}
      </div>
    )
  }

  render() {
    if (this.props.selectedLocation) return null
    const { isFilterPanelOpen, filters, translate, onFilterToggle } = this.props
    return (
      <div className={`inline-filters ${isFilterPanelOpen ? "inline-filters--open" : ""}`}>
        <QuickFilters
          title={isFilterPanelOpen ? this.props.translate("filters.title") : null}
          filters={filters}
          showFilters={!isFilterPanelOpen}
          translate={translate}
          renderButton={() => this.renderButton()}
          onFilterToggle={onFilterToggle}
        />
      </div>
    )
  }
}

export default InlineFilters

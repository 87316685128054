import React from "react"
import LocationInfo from "@geome/shell-locator-components/lib/components/locationInfo"
import Pane from "@geome/shell-locator-components/lib/components/pane"
import PropTypes from "prop-types"
import LocationDetails from "./sections/locationDetails"
import Link from "@geome/shell-locator-components/lib/components/util/link"
import AppointmentButton from "../AppointmentButton"
import { translate } from "@geome/intl"
import { OpenInGoogleMaps } from "../utils/openInGoogleMaps"
import {
  Pin,
  Phone,
  Earth,
  Fax,
  Email,
} from "@geome/shell-locator-components/lib/components/locationInfo/icons"

class InfoWindowView extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      address1: PropTypes.string,
      city: PropTypes.string,
      postcode: PropTypes.string,
      url: PropTypes.string,
      other_url: PropTypes.string,
      telephone: PropTypes.string,
      fax: PropTypes.string,
      fuels: PropTypes.arrayOf(PropTypes.string),
      country_code: PropTypes.string,
      email: PropTypes.string,
      lube_products: PropTypes.array,
      channel_types: PropTypes.array,
      sectors: PropTypes.array,
      user_type: PropTypes.string,
    }),
    translate: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    shouldShowAppointmentButton: PropTypes.bool,
  }

  static defaultProps = {
    translate: translate,
  }

  locationDetailsSection() {
    return {
      name: "location-details",
      icon: <Pin />,
      head: <LocationDetails location={this.props.location} />,
    }
  }

  telephoneSection() {
    const { telephone } = this.props.location
    return {
      name: "telephone",
      icon: <Phone />,
      head: (
        <Link href={telephone} defaultScheme="tel:">
          {telephone}
        </Link>
      ),
    }
  }

  appointmentButtonSection() {
    const { location, translate, shouldShowAppointmentButton } = this.props
    if (!location.id || !shouldShowAppointmentButton) return null
    return {
      name: "appointment-button",
      icon: "",
      head: <AppointmentButton {...location} translate={translate}></AppointmentButton>,
    }
  }

  faxSection() {
    const { fax } = this.props.location
    return {
      name: "fax",
      icon: <Fax />,
      head: (
        <Link href={fax} defaultScheme="tel:">
          {fax}
        </Link>
      ),
    }
  }

  websiteSection() {
    const { url } = this.props.location
    return {
      name: "website",
      icon: <Earth />,
      head: (
        <Link href={url} defaultScheme="http:" external={true}>
          {url}
        </Link>
      ),
    }
  }

  otherWebsiteSection() {
    const { other_url: otherUrl } = this.props.location
    return {
      name: "other-website",
      icon: <Earth />,
      head: (
        <Link href={otherUrl} defaultScheme="http:" external={true}>
          {otherUrl}
        </Link>
      ),
    }
  }

  emailSection() {
    const { email } = this.props.location
    return {
      name: "email",
      icon: <Email />,
      head: <Link href={`mailto:${email}`}>{email}</Link>,
    }
  }

  lubeProductsSection() {
    const { lube_products: lubeProducts } = this.props.location
    return {
      name: "lube-products",
      requireBody: true,
      head: translate("filters.lube_products.title"),
      body: lubeProducts.map((lubeProduct) => (
        <div key={lubeProduct}>{translate(`lube_products.${lubeProduct}`)}</div>
      )),
      openByDefault: lubeProducts.length === 1,
    }
  }

  locationTypesSection() {
    const { channel_types: locationTypes } = this.props.location
    return {
      name: "location-type",
      requireBody: true,
      head: translate("filters.channel_types.title"),
      body: locationTypes.map((locationType) => (
        <div key={locationType}>{translate(`channel_types.${locationType}`)}</div>
      )),
      openByDefault: locationTypes.length === 1,
    }
  }

  sectorsSection() {
    const { sectors } = this.props.location
    return {
      name: "sectors",
      requireBody: true,
      head: translate("filters.sectors.title"),
      body: sectors.map((sector) => <div key={sector}>{translate(`sectors.${sector}`)}</div>),
      openByDefault: sectors.length === 1,
    }
  }

  userTypeSection() {
    const userTypes = [this.props.location.user_type]
    return {
      name: "user-type",
      requireBody: true,
      head: translate("filters.user_types.title"),
      body: userTypes.map((userType) => (
        <div key={userType}>{translate(`user_types.${userType}`)}</div>
      )),
      openByDefault: userTypes.length === 1,
    }
  }

  workshopServicesSection() {
    const { workshop_services: workShopServices } = this.props.location
    if (!workShopServices?.length) return null
    return {
      name: "workshop_services",
      requireBody: true,
      head: translate("filters.workshop_services.title"),
      body: workShopServices.map((service) => (
        <div key={service}>{translate(`workshop_services.${service}`)}</div>
      )),
      openByDefault: workShopServices.length === 1,
    }
  }

  promotionsSection() {
    const { promotions } = this.props.location
    if (!promotions?.length) return null
    return {
      name: "promotions",
      requireBody: true,
      head: translate("filters.promotions.title"),
      body: promotions.map((promotion) => (
        <div key={promotion}>{translate(`promotion.${promotion}`)}</div>
      )),
      openByDefault: promotions.length === 1,
    }
  }

  render() {
    const { location, translate, isMobile } = this.props
    return (
      <Pane className="pane--cut-off-background-colour u-flex-grow-1 group-padding-x">
        <LocationInfo
          location={location}
          translate={translate}
          shouldShowDirectionsButton
          onDirectionsClick={(location) => OpenInGoogleMaps(location)}
          shouldShowBigButtons={isMobile}
          sections={[
            this.appointmentButtonSection(),
            this.locationDetailsSection(),
            this.telephoneSection(),
            this.faxSection(),
            this.websiteSection(),
            this.otherWebsiteSection(),
            this.emailSection(),
            this.lubeProductsSection(),
            this.locationTypesSection(),
            this.sectorsSection(),
            this.userTypeSection(),
            this.workshopServicesSection(),
            this.promotionsSection(),
          ].filter(Boolean)}
        />
      </Pane>
    )
  }
}

export default InfoWindowView
